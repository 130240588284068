<template>
  <div>
    <guest-quote ref="showQuote" :quote="showQuote" @close-modal="closeQuotationModal" /> 
  <header id="home">
    <nav id="nav" :class="{ 'fixed-nav': isNavbarFixed }" class="landing-nav">
      <div class="nav-center">
        <div class="nav-header">
          <img src="mtenderlogo.png" alt="logo" class="logo" />
          <button class="nav-toggle">
            <i class="fas fa-bars"></i>
          </button>
        </div>
        <div class="links-container">
          <ul class="links">
            <img src="navline.png" alt="navline" id="navline" class="navline" />

                        <li class="line-item"></li>

          <li>
            <div style="border-top: 1px solid black;"></div>
          </li>
            <li>
              <a href="#home" class="scroll-link" >MTENDERS LISTING</a>
            </li>

              <li>
                <router-link :to="{ name: 'login' }" class="scroll-link"> LOGIN </router-link>
              </li>
            <li>
              <router-link :to="{ name: 'register' }" class="scroll-link"> CREATE ACCOUNT </router-link>
            </li>
          </ul>  
        </div>
      </div>
  </nav>
    <div class="banner">
      <div class="container">
        <div class="row align-items-end justify-content-center bond-parent">
          <div class="col-md-6 bond-content">
            <h2 class="map-title" style="color:#E6E4E4;font-size:68px"><b>Bid Bonds</b></h2>
            <h4 class="map-paragraph" style="color:#E6E4E4;font-size:20px"><b>Apply for Bid Bonds online</b></h4>
            <button class="btn-locate btn-primary header-nav" ref="requestQuoteButton1" @click="showQuoteForm" >Request Quote</button>
          </div>
          <div class="col-md-6 bond-content"> 
            <h2 class="map-title" style="color:#E6E4E4;font-size:60px"><b>Performance Bonds</b></h2>
            <h4 class="map-paragraph" style="color:#E6E4E4;font-size:20px"><b>Get Performance Bonds online</b></h4>
            <button class="btn-locate btn-primary  header-nav" ref="requestQuoteButton2"  @click="showQuoteForm">Request Quote</button>
          </div>
        </div>
        </div>
    </div>
  </header>
  <section id="about" class="section">
    <div class="row">
      <div class="col-md-2">
        </div>
        <div class="col-md-4">
          <img src="map.png" style="margin-top:30px" />
          </div>
          <div class="col-md-4 locate-landing">
            <h2 class="map-title">Get Your Bid Bond within 5 minutes</h2>
            <h2 class="map-word">Locate your nearest DTB Branch<br> or ATM with Ease</h2>
            <br>
            <button class="btn-locate btn-primary" style="margin-top:20px">Locate us</button>
          </div>
          <div class="col-md-2">

            </div>
    </div>
    <div class="row">
      <div class="col-md-2">
        </div>
        <div class="col-md-4 talk-landing">
            <h2 class="map-title">Upto 5 million PB without cash cover</h2>
            <p class="map-word">Have any questions?Need to know more about our products?We are available 24/7 to help you with your 
              banking needs
            </p>
            <br>
            <button class="btn-locate btn-primary" style="margin-top:20px">Talk to us</button>
          </div>
      
        <div class="col-md-4">
          <img src="mtendwebap.png" style="margin-top:30px" />
          </div>
          
          <div class="col-md-2">

            </div>
    </div>
    <div class="row">
      <div class="col-md-2">
        </div>
        <div class="col-md-4 ">
          <img src="mtendapp.png" style="margin-top:30px" />
          </div>
          <div class="col-md-4 mobile-landing">
            <h2 class="map-title">Mtenders 24/7 App</h2>
            <p class="map-word">Enjoy the convenience of banking on the go 24/7! Download our
              mobile app today
            </p>        <br>
            <img src="googleplay.png" class="google-play" style="margin-top:30px" />
          </div>
          <div class="col-md-2">

            </div>
    </div>
    <div class="row">
      <div class="col-md-2">
      </div>
      <div class="col-md-8">
        <div class="rectangle">
          <h3 class="rectangle-title">Want to get started? 
            
          </h3>
          <router-link to="/register" class="btn-primary-signup btn-block">
              Sign Up
            </router-link>
        </div>

      </div>
      <div class="col-md-2">

      </div>
    </div>
    <footer  id="landing-footer" style="background-color:black">  
    <p style="color:white" id="footer-landing"> &copy; <span id="date"></span> Mtenders. All rights reserved</p>
    </footer>
  </section>

  
  </div>
</template>


<script>
import GuestQuote from '@/components/GuestQuote.vue';
export default {
  components: {
    GuestQuote,
  },
  data() {
    return {
      isNavbarFixed: false,
      showQuote: false,
    };
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  //on mounted generate the year for the footer
  const date = new Date();
  document.getElementById('date').innerHTML = date.getFullYear();
  window.addEventListener('click', this.handleClickOutside);


  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('click', this.handleClickOutside);

  },
  methods: {
    handleScroll() {
      if (window.pageYOffset > 0) {
        this.isNavbarFixed = true;
      } else {
        this.isNavbarFixed = false;
      }
    },
    closeQuotationModal(){
      this.showQuote = false;

    },
    showQuoteForm(){
            this.showQuote = true;

    },
    handleClickOutside(event) {
        const showQuoteElement = this.$refs.showQuote.$el; // Access the root DOM element of the component
        const requestQuoteButton1 = this.$refs.requestQuoteButton1;
        const requestQuoteButton2 = this.$refs.requestQuoteButton2;

      if (
        showQuoteElement &&
        !showQuoteElement.contains(event.target) &&
        event.target !== requestQuoteButton1 &&
        event.target !== requestQuoteButton2
      ) {
        this.closeQuotationModal();
      }
    }

  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.btn-primary-signup{
  background-color: white;
  color: green;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  width: 100%;
  border-radius:15px;
  max-width: 200px; /* Adjust as needed */
  text-align: center;

}

.btn-primary-signup:hover {
  background-color: gray;
  color:white;
}


.rectangle {
  background-color: #459247;
  border-radius: 20px;
  padding: 20px;
  margin-top:40px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  
}

.rectangle-title {
  color: white;
  text-align: center;
  padding:20px;
}

.rectangle-button {
  text-align: center;
  background-color: white;
  color: green;
  border: none;
  padding: 10px 20px;
  margin-top: 20px;
  cursor: pointer;
}

/*
=============== 
Global Styles
===============
*/
html {
  scroll-behavior: smooth;
}
*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: var(--ff-secondary);
  background: var(--clr-grey-10);
  color: var(--clr-grey-1);
  line-height: 1.5;
  font-size: 0.875rem;
}
.google-play{
  margin-left: 14px;
  width:190px !important;
}

.locate-landing {
  margin-left:20px;
}

.talk-landing{
margin-right:20px
}
.mobile-landing{
margin-left:20px;
}

.bond-parent{
  display:flex;
}

.bond-content{

  margin-top:180px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.col-md-6 {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}


.btn-locate{
  background-color: #0B9246;
  color: white;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 1.2rem;
  font-family: Calisto MT;

}
ul {
  list-style-type: none;
}
a {
  text-decoration: none;
}
img:not(.logo) {
  width: 100%;
}
img {
  display: block;
}

h1,
h2,
h3,
h4 {
  letter-spacing: var(--spacing);
  text-transform: capitalize;
  line-height: 1.25;
  margin-bottom: 0.75rem;
  font-family: var(--ff-primary);
}

.map-title{
  color:#0B9246;
  margin-top: 60px;
  margin-left:6px;
  font-family: Calisto MT;
}


.map-paragraph{
  color:#0B9246;
  margin-top: 20px;
  margin-left:6px;
  font-family: Calisto MT;
}
h1 {
  font-size: 3rem;
}
h2 {
  font-size: 2rem;
}
h3 {
  font-size: 1.25rem;
}
h4 {
  font-size: 0.875rem;
}
p {
  margin-bottom: 1.25rem;
  color: var(--clr-grey-5);
}
@media screen and (min-width: 800px) {
  h1 {
    font-size: 4rem;
  }
  h2 {
    font-size: 2.5rem;
  }
  h3 {
    font-size: 1.75rem;
  }
  h4 {
    font-size: 1rem;
  }
  body {
    font-size: 1rem;
  }
  h1,
  h2,
  h3,
  h4 {
    line-height: 1;
  }
}
/*  global classes */

.btn {
  text-transform: uppercase;
  background: transparent;
  color: var(--clr-black);
  padding: 0.375rem 0.75rem;
  letter-spacing: var(--spacing);
  display: inline-block;
  transition: var(--transition);
  font-size: 0.875rem;
  border: 2px solid var(--clr-black);
  cursor: pointer;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: var(--radius);
}

.line-item::before {
  content: "";
  position: absolute;
  bottom: -3px;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 20px);
  height: 3px;
  background-color: #459247;
}
.btn:hover {
  color: var(--clr-white);
  background: var(--clr-black);
}
.btn-white {
  color: var(--clr-white);
  border-color: var(--clr-white);
  border-radius: 0;
  padding: 0.5rem 1rem;
}
.btn:hover {
  background: var(--clr-white);
  color: var(--clr-secondary);
}
/* section */
.section {
  padding-bottom: 5rem;
}

.section-center {
  width: 90vw;
  margin: 0 auto;
  max-width: 1170px;
}
@media screen and (min-width: 992px) {
  .section-center {
    width: 95vw;
  }
}
main {
  min-height: 100vh;
  display: grid;
  place-items: center;
}
/*
=============== 
Scroll
===============
*/
/* navbar */
nav {
  background: var(--clr-white);
  padding: 1rem 1.5rem;
  width: 100%;
  box-shadow: var(--light-shadow);
  z-index: 1000; /* Set a higher z-index value */
}
.nav-center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-header {
  display: flex;
  align-items: center;
  margin-left:-40px;
}

.links-container {
  display: flex;
  align-items: center;
}

.links {
  list-style: none;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
}

.line-item {
  flex-grow: 1;
}

.links li a {
  position: relative;
  color: black;
  text-decoration: none;
}
/* fixed nav */
.fixed-nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: var(--clr-white);
  box-shadow: var(--light-shadow);
}

#landing-footer{

  text-align:left;
  padding: 1rem 1rem;

}
.map-word{
  font-family: Calisto MT;
  color: black;
  font-size: 1.2rem;
  margin-left:8px;
}
.fixed-nav .links a {
  color: var(--clr-grey-1);
}
.fixed-nav .links a:hover {
  color: var(--clr-secondary);
}
 .links-container {
  height: auto !important;
} 
.nav-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.nav-toggle {
  font-size: 1.5rem;
  color: var(--clr-grey-1);
  background: transparent;
  border-color: transparent;
  transition: var(--transition);
  cursor: pointer;
}
.nav-toggle:hover {
  color: var(--clr-secondary);
}
.logo {
  height: 50px;
}
.links-container {
  height: 0;
  overflow: hidden;
  transition: var(--transition);
}
.show-links {
  height: 200px;
}
.links a {
  background: var(--clr-white);
  color: var(--clr-grey-5);
  font-size: 1.1rem;
  text-transform: capitalize;
  letter-spacing: var(--spacing);
  display: block;
  transition: var(--transition);
  font-weight: bold;
  padding: 0.75rem 0;
}
.links a:hover {
  color: var(--clr-grey-1);
}
@media screen and (min-width: 800px) {
  nav {
    background: transparent;
  }
  .nav-center {
    width: 90vw;
    max-width: 1170px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .nav-header {
    padding: 0;
  }
  .nav-toggle {
    display: none;
  }
  .links-container {
    height: auto !important;
  }
  .links {
    display: flex;
  }

  #navline{
    width: 40%;
    background: green;
    margin-top: 20px;


  }
  .links a {
    background: transparent;
    color: var(--clr-white);
    font-size: 1.1rem;
    text-transform: capitalize;
    letter-spacing: var(--spacing);
    display: block;
    transition: var(--transition);
    margin: 0 1rem;
    font-weight: bold;
    padding: 0;
  }
  .links a:hover {
    color: var(--clr-white);
  }
}
/* hero */
header {
  min-height: 100vh;
  background:  url(/bannerlandi.png) center/cover no-repeat;
}


.banner {
  min-height: 100vh;
  background-color: rgba(0, 0, 0, 0.5); /* Adjust the opacity as needed */

  display: grid;
  place-items: center;
  text-align: center;
}

.container h1 {
  color: var(--clr-white);
  text-transform: uppercase;
}
.container p {
  color: var(--clr-white);
  max-width: 25rem;
  margin: 0 auto;
  margin-bottom: 1.25rem;
}

.scroll-link{
  font-family: Calisto MT;
  
}
.scroll-link:hover {
  color: #459247 !important;
}

@media screen and (min-width: 768px) {
  .container p {
    max-width: 40rem;
  }
}
/* sections and title */
.title h2 {
  text-align: center;
  text-transform: uppercase;
}
.title span {
  color: var(--clr-secondary);
}


#footer-landing{
  text-align:left !important;
}
#about,
#services,
#tours {
  height: 80vh;
}
#services {
  background: var(--clr-white);
}
footer {
  background: var(--clr-grey-1);
  padding: 5rem 1rem;
}
footer p {
  color: var(--clr-white);
  text-align: center;
  text-transform: capitalize;
  letter-spacing: var(--spacing);
  margin-bottom: 0;
}

.parastro{
  font-size: 2.25rem;
  font-family: verdana;
text-align: center;
}
.top-link {
  font-size: 1.25rem;
  position: fixed;
  bottom: 3rem;
  right: 3rem;
  background: var(--clr-secondary);
  width: 2rem;
  height: 2rem;
  display: grid;
  place-items: center;
  border-radius: var(--radius);
  color: var(--clr-white);
  animation: bounce 2s ease-in-out infinite;

  visibility: hidden;
  z-index: -100;
}
.show-link {
  visibility: visible;
  z-index: 100;
}

@keyframes bounce {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}

.landing-nav{
  background-color:white !important;
}

nav[data-v-5e7337c8] {
    background: var(--clr-white);
    padding: 7px 1.5rem;
}
</style>
